import React from "react";
import {render} from "react-dom";
import "../../css/index_common.scss";
import Footer from '../components/Footer';
import seal from '../../static/img/Seal_of_Utah.svg';

const IntroParagraph = () => {
  return (
    <>
      <div className='main-image-seal'>
        <img src={seal} alt="State of Utah seal"  className='main-text-image' />
      </div>
      <div className="main-container__text">
        <h1> Measuring the Use of Utah Taxpayer Dollars </h1>
        <div className='main-container__supportTextContainer'>
          <p>Utah law requires that programs and projects receiving more than $10,000 in Utah taxpayer funds submit performance metrics.</p>
          <p>
            Many of these measures are written into agencies' <a href={'https://le.utah.gov/search.jsp?String=Appropriations&Submit=Find'} target={'_blank'}>appropriations bills</a>, which provide funding for those programs.
            In August, programs report the data outcomes for those performance measures to the <a href={'https://gopb.utah.gov/'} target='_blank'>Governor's Office of Planning and Budget</a>
            and the <a href={'https://le.utah.gov/lfa/index.htm'} target={'_blank'}> Office of the Legislative Fiscal Analyst.</a>
            This information helps both the executive and legislative branches understand the work and impact of taxpayer-funded programs.
          </p>
          <p>Contact <a href="mailto:performance@utah.gov">performance@utah.gov</a> with questions or suggestions regarding these performance measures.</p>
        </div>
      </div>
    </>
  );
}

export default IntroParagraph;
